import http from "../utils/http";

export class ServiceFactory {
  constructor() {}

  /**
   * QUOTES
   */
  static getQuotes = async params =>
    (await http.get("api/service/quotes", { params })).data;

  static getQuote = async id =>
    (await http.get(`api/service/quotes/${id}`)).data;

  static saveQuote = async reg => {
    if (reg.id)
      return (await http.put(`api/service/quotes/${reg.id}`, reg)).data;
    else return (await http.post("api/service/quotes", reg)).data;
  };

  static deleteQuote = async id =>
    (await http.delete(`api/service/quotes/${id}`)).data;

  static getEmployeSChedule = async params => {
    return (await http.get("api/service/employee-schedule", { params })).data;
  };

  static getTreatmentPlanHistory = async id => {
    return (await http.get("api/service/treatment-plans/" + id, {
      params: { history: 1 }
    })).data;
  };

  static getFinancings = async params =>
    (await http.get("api/service/financings", { params })).data;

  static getFinancing = async id =>
    (await http.get(`api/service/financings/${id}`)).data;

  static saveFinancing = async reg => {
    if (reg.id)
      return (await http.put(`api/service/financings/${reg.id}`, reg)).data;
    else return (await http.post("api/service/financings", reg)).data;
  };

  static deleteFinancing = async id =>
    (await http.delete(`api/service/financings/${id}`)).data;

  static scheduleTimes = async params => {
    return (await http.get("api/service/schedule-times", { params })).data;
  };

  static getContactTasks = async params =>
    (await http.get("api/service/contact-tasks", { params })).data;

  static getContactTask = async id =>
    (await http.get(`api/service/contact-tasks/${id}`)).data;

  static saveContactTask = async reg => {
    if (reg.id)
      return (await http.put(`api/service/contact-tasks/${reg.id}`, reg)).data;
    else return (await http.post("api/service/contact-tasks", reg)).data;
  };

  static deleteContactTask = async id =>
    (await http.delete(`api/service/contact-tasks/${id}`)).data;
}
