<template>
  <div>
    <div class="form-group">
      <label for="">Detalle de tarea</label>
      <input type="text" class="form-control" v-model="note" />
    </div>
    <div class="form-group">
      <label for="">Fecha </label>
      <app-datepicker v-model="date_to_contact"></app-datepicker>
    </div>
    <div class="form-group">
      <label for="">Paciente</label>
      <SelectClient ref="selCli" v-model="client_id"></SelectClient>
    </div>
    <div class="form-group">
      <label for="">Tipo</label>
      <SelectType v-model="type_id"></SelectType>
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import SelectClient from "../../store-module/clients/Select";
import { ServiceFactory } from "../../service-module/service";
import SelectType from "./SelectType";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    SelectClient,
    SelectType
  },

  // directives
  // filters

  props: {
    clientId: {}
  },

  data: () => ({
    client_id: null,
    date_to_contact: null,
    note: "",
    type_id: 1
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.$refs.selCli.reset();
      this.client_id = null;
      this.date_to_contact = myMoment().format("YYYY-MM-DD");
      this.note = null;
      this.type_id = 1;

      if (this.clientId) {
        this.$refs.selCli.setValueFromId(this.clientId);
      }
    },
    save() {
      ServiceFactory.saveContactTask(this.$data).then(res => {
        this.$emit("submitted", res);
      });
    }
  }
};
</script>

<style></style>
