<template>
  <span
    class="badge badge-primary"
    :style="`background-color: ${getType(typeId).color}`"
  >
    {{ getType(typeId).name }}
  </span>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    typeId: {}
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      types: s => s.config.service.contact_task.types
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    getType(type_id) {
      return this.types[type_id] || {};
    }
  }
};
</script>

<style></style>
