<template>
  <select
    class="custom-select"
    :value="value"
    @input="$emit('input', $event.target.value)"
  >
    <option v-for="(l, i) in list" :key="i" :value="i"> {{ l.name }} </option>
  </select>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {}
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      list: s => s.config.service.contact_task.types  
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
