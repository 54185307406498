<template>
  <div>
    <table v-show="single.id" class="mb-2">
      <tbody>
        <tr class="client-name">
          <td class="p-2">
            <h5><i class="fa fa-user"></i></h5>
          </td>
          <td>
            <router-link
              :to="`/dental-clinic/patients/${single.client_id}`"
              class="text-dark text-decoration-none"
            >
              <h5>{{ single.client_name }}</h5>
            </router-link>
          </td>
        </tr>
        <tr>
          <td class="p-2"><i class="fa fa-tag"></i></td>
          <td>
            <div class="pull-left label category cita">
              <spanType :typeId="single.type_id"></spanType>
            </div>
          </td>
        </tr>
        <tr>
          <td class="p-2">
            <i class="fa fa-phone"></i>
          </td>
          <td>
            {{ single.client_phone_number }}
          </td>
        </tr>
        <tr>
          <td class="p-2"><i class="fa fa-calendar"></i></td>
          <td>
            {{ single.client_birthdate | age }}
          </td>
        </tr>

        <tr>
          <td class="p-2">
            <i class="far fa-envelope"></i>
          </td>
          <td>{{ single.note }}</td>
        </tr>

        <tr v-show="!single.maked && !single.closed">
          <td class="p-2">
            <i class="far fa-check-square"></i>
          </td>
          <td>
            <div class="dropdown">
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Finalizar
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  class="dropdown-item"
                  :to="{
                    path: `/dental-clinic/weekly-agenda/${single.employee_id}`,
                    query: {
                      client_id: single.client_id,
                      quote_id: single.quote_id || undefined,
                      task: single.id
                    }
                  }"
                >
                  Agendar
                </router-link>

                <!-- <div class="dropdown-divider"></div> -->
                <a
                  class="dropdown-item bg-success"
                  href="#"
                  @click.prevent="$emit('reCall', single.id)"
                  >Volver a contactar</a
                >
                <!-- <div class="dropdown-divider"></div> -->
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="$emit('closeCase', single.id)"
                >
                  Cerrar el caso
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="$emit('setMakedTask', single.id)"
                >
                  Respuesta positiva
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-item nav-link"
          :class="{ active: menu == 1 }"
          @click.prevent="menu = 1"
          href="#"
          >Comentarios</a
        >
        <a
          class="nav-item nav-link"
          :class="{ active: menu == 2 }"
          @click.prevent="menu = 2"
          href="#"
          >Presupuestos</a
        >
        <a
          class="nav-item nav-link"
          :class="{ active: menu == 3 }"
          @click.prevent="menu = 3"
          href="#"
          >Citas</a
        >
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade" :class="{ 'show active': menu == 1 }">
        <app-comments :url="`dental_client_${single.client_id}`"></app-comments>
      </div>
      <div class="tab-pane fade" :class="{ 'show active': menu == 2 }"></div>
      <div class="tab-pane fade" :class="{ 'show active': menu == 3 }"></div>
    </div>
  </div>
</template>
<script>
import spanType from "./spanType";

export default {
  components: {
    spanType
  },

  // directives
  // filters

  props: {
    single: {
      default: () => {
        return {
          maked: 0
        };
      }
    }
  },

  data: () => ({
    menu: 1
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
