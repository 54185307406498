<template>
  <div>
    <h4>TAREAS DE CONTACTO</h4>

    <div class="row mb-2">
      <div class="col-md-6">
        <button
          class="btn btn-success"
          @click="
            $refs.elModalForm.show();
            $refs.elForm.reset();
          "
        >
          <i class="fa fa-plus"></i> Agregar tarea
        </button>
      </div>
      <div class="col-md-3">
        <SelectEmployee
          placeholder="Filtrar x Doctor"
          :specialties="config_treatments.specialty_id"
          v-model="employee_id"
        ></SelectEmployee>
      </div>
      <div class="col d-flex">
        <app-datepicker
          v-model="currrentDate"
          @input="getList()"
        ></app-datepicker>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col col-lg-7">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: menu == 1 }"
              @click.prevent="menu = 1"
            >
              <span>Tareas del dia </span>
              <span class="badge badge-primary">{{ todayList.length }} </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: menu == 2 }"
              @click.prevent="menu = 2"
            >
              Realizadas
              <span class="badge badge-secondary">{{ listMaked.length }} </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: menu == 3 }"
              @click.prevent="menu = 3"
            >
              Atrasadas
              <span class="badge badge-success">{{ list.length }} </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: menu == 4 }"
              @click.prevent="menu = 4"
            >
              Casos Cerrados
              <span class="badge badge-danger">{{ closeCases.length }} </span>
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade" :class="{ 'show active': menu == 1 }">
            <myTable
              :selectedId="single.id"
              :list="todayList"
              @clickRow="single = $event"
              :employeeId="employee_id"
            ></myTable>
          </div>
          <div class="tab-pane fade" :class="{ 'show active': menu == 2 }">
            <myTable
              :selectedId="single.id"
              :list="listMaked"
              @clickRow="single = $event"
              :employeeId="employee_id"
            ></myTable>
          </div>
          <div class="tab-pane fade" :class="{ 'show active': menu == 3 }">
            <myTable
              :selectedId="single.id"
              :list="list"
              @clickRow="single = $event"
              :employeeId="employee_id"
            ></myTable>
          </div>
          <div class="tab-pane fade" :class="{ 'show active': menu == 4 }">
            <myTable
              :selectedId="single.id"
              @clickRow="single = $event"
              :list="closeCases"
              :employeeId="employee_id"
            ></myTable>
          </div>
        </div>
      </div>
      <div class="col col-lg-5">
        <ctrl-contact-task
          :single="single"
          @setMakedTask="setMakedTask(single.id)"
          @reCall="
            $refs.elModalRecall.show();
            setToCallToday();
          "
          @closeCase="closeCase(single.id)"
        ></ctrl-contact-task>
      </div>
    </div>

    <app-modal-basic ref="elModalForm">
      <Form ref="elForm" @submitted="$refs.elModalForm.hide()"></Form>
    </app-modal-basic>

    <app-modal-basic ref="elModalRecall">
      <div class="form-group">
        <label for="">Fecha</label>
        <app-datepicker v-model="to_call"></app-datepicker>
      </div>
      <app-button-submit @click="saveContactTask()"></app-button-submit>
    </app-modal-basic>
  </div>
</template>
<script>
// import { DentalClinicService } from "../service";
import Form from "./Form";
import { myMoment, momentDateFormat } from "../../utils/myMoment";
import myTable from "./Table";
import { mapState } from "vuex";
// import spanType from "./spanType";
import { ServiceFactory } from "../../service-module/service";
import ctrlContactTask from "./Ctrl";
import SelectEmployee from "../../admin-module/employees/Select";

export default {
  components: {
    Form,
    myTable,
    // spanType,
    ctrlContactTask,
    SelectEmployee
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    currrentDate: null,
    todayList: [],
    list: [],
    listMaked: [],
    closeCases: [],
    single: {},
    menu: 1,
    employee_id: 0,
    to_call: myMoment().format("YYYY-MM-DD")
  }),

  computed: {
    ...mapState({
      types: s => s.config.service.contact_task.types,
      config_treatments: s => s.config.dental.treatments
    })
  },

  watch: {
    //
  },

  mounted() {
    this.currrentDate = myMoment().format(momentDateFormat);
    this.getList();
  },

  methods: {
    closeCase() {
      ServiceFactory.saveContactTask({
        id: this.single.id,
        close: 1
      }).then(() => {
        this.single = {};
        this.getList();
      });
    },
    setMakedTask() {
      ServiceFactory.saveContactTask({
        id: this.single.id,
        perform: 1
      }).then(() => {
        this.single = {};
        this.getList();
      });
    },
    nameType(type_id) {
      return this.types[type_id] && this.types[type_id].name;
    },
    colorType(type_id) {
      return this.types[type_id] && this.types[type_id].color;
    },
    saveContactTask() {
      ServiceFactory.saveContactTask({
        ...this.single,
        id: undefined,
        contact_task_id: this.single.id,
        date_to_contact: this.to_call
      }).then(() => {
        this.$refs.elModalRecall.hide();
        this.single = {};
        this.getList();
      });
    },
    getList() {
      return new Promise(rsv => {
        let reqParams = {};

        if (this.client_id) reqParams["client_id"] = this.client_id;
        else reqParams["date"] = this.currrentDate;

        ServiceFactory.getContactTasks(reqParams).then(res => {
          // let today = myMoment(myMoment(), "YYYY-MM-DD");

          this.todayList = res.filter(x => {
            return (
              myMoment(x.date_to_contact, "YYYY-MM-DD").isSame(
                this.currrentDate
              ) &&
              !x.closed &&
              !x.maked
            );
          });

          this.listMaked = res.filter(x => {
            return (
              myMoment(x.date_to_contact, "YYYY-MM-DD").isSame(
                this.currrentDate
              ) &&
              !x.closed &&
              x.maked
            );
          });

          this.list = res.filter(x => {
            // console.log(myMoment(x.date_to_contact));
            return (
              myMoment(x.date_to_contact, "YYYY-MM-DD").isBefore(
                this.currrentDate
              ) &&
              !x.closed &&
              !x.maked
            );
          });

          this.closeCases = res.filter(x => {
            return x.closed;
          });

          this.single = this.todayList.length > 0 ? this.todayList[0] : {};

          rsv(res);
        });
      });
    },
    setToCallToday() {
      this.to_call = myMoment().format("YYYY-MM-DD");
    }
  }
};
</script>

<style></style>
