var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.listFiltered),function(l){return _c('tr',{key:l.id,class:{
        'table-info': _vm.selectedId == l.id && !l.maked,
        'bg-light': l.maked
      },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('clickRow', l)}}},[_c('td',[_c('i',{staticClass:"far ",class:{
            'fa-square': !l.maked,
            'fa-check-square': l.maked,
            'fa-window-close': l.closed
          }})]),_c('td',[_c('spanType',{attrs:{"typeId":l.type_id}})],1),_c('td',[_c('span',[_vm._v(_vm._s(l.client_name))]),_c('div',[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(l.employee_name)+" ")])])]),_c('td',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(l.user_name || "No asignado")+" ")]),_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("simpleDate")(l.date_to_contact)))]),_vm._m(0,true)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('i',{staticClass:"fa fa-chevron-right"})])}]

export { render, staticRenderFns }