<template>
  <table class="table">
    <tbody>
      <tr
        v-for="l in listFiltered"
        :key="l.id"
        @click="$emit('clickRow', l)"
        style="cursor: pointer;"
        :class="{
          'table-info': selectedId == l.id && !l.maked,
          'bg-light': l.maked
        }"
      >
        <td>
          <i
            class="far "
            :class="{
              'fa-square': !l.maked,
              'fa-check-square': l.maked,
              'fa-window-close': l.closed
            }"
          ></i>
        </td>
        <td>
          <spanType :typeId="l.type_id"></spanType>
        </td>
        <td>
          <span>{{ l.client_name }}</span>
          <div>
            <small class="text-muted">{{ l.employee_name }} </small>
          </div>
        </td>
        <td class="text-muted">
          {{ l.user_name || "No asignado" }}
        </td>
        <td class="text-nowrap">{{ l.date_to_contact | simpleDate }}</td>
        <td>
          <i class="fa fa-chevron-right"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import spanType from "./spanType";

export default {
  components: {
    spanType
  },

  // directives
  // filters

  props: {
    employeeId: {},
    list: {},
    selectedId: {}
  },

  data: () => ({
    //
  }),

  computed: {
    listFiltered() {
      return this.list.filter(
        x => !this.employeeId || x.employee_id == this.employeeId
      );
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
